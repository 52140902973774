// This can be replaced by using a third-party library such as
// [https://github.com/dcodeIO/ProtoBuf.js/wiki](https://github.com/dcodeIO/ProtoBuf.js/wiki)

/**
 * encodeChallenge convert JSON challenge into base64 encoded byte array
 *
 * @param {Object} challenge - The challenge object containing `data` and `signature`.
 * @return {String} The base64 encoded byte array.
 */
export function encodeChallenge(challenge) {
    const protobufBinary = protoEncodeChallenge(
        window.atob(challenge.data),
        window.atob(challenge.signature),
    );

    return window.btoa(protobufBinary);
}

/**
 * protoEncodeChallenge produce binary encoding of the challenge protobuf
 *
 * @param {String} dataBinary - The binary string representing the challenge data.
 * @param {String} signatureBinary - The binary string representing the signature.
 * @return {String} The binary encoding of the challenge protobuf.
 */
export function protoEncodeChallenge(dataBinary, signatureBinary) {
    let protoEncoded = '';

    // See https://developers.google.com/protocol-buffers/docs/encoding
    // for encoding details.

    // 0x0A (00001 010, field number 1, wire type 2 [length-delimited])
    protoEncoded += '\u000A';

    // encode length of the data
    protoEncoded += varintEncode(dataBinary.length);
    // add data
    protoEncoded += dataBinary;

    // 0x12 (00010 010, field number 2, wire type 2 [length-delimited])
    protoEncoded += '\u0012';
    // encode length of the signature
    protoEncoded += varintEncode(signatureBinary.length);
    // add signature
    protoEncoded += signatureBinary;

    return protoEncoded;
}

/**
 * varintEncode produce binary encoding of the integer number
 *
 * @param {Number} number - The number to encode.
 * @return {String} The binary encoding of the number.
 */
function varintEncode(number) {
    // This only works correctly for numbers 0 through 16383 (0x3FFF)
    if (number <= 127) {
        return String.fromCharCode(number);
    }
    return String.fromCharCode(128 + (number & 0x7f), number >>> 7);
}

function varintDecode(string) {
    // This only works correctly for numbers 0 through 16383 (0x3FFF)
    if (string.length === 1) {
        return string.charCodeAt(0);
    }
    return ((string.charCodeAt(1) << 7) + (string.charCodeAt(0) - 128));
}

/**
 * decodeSignedData convert base64 encoded SignedData protobuf into SignedData object
 *
 * @param {String} base64SignedData - The base64 encoded SignedData protobuf.
 * @return {Object} The decoded SignedData object containing `data` and `signature`, both base64 encoded.
 */
export function decodeSignedData(base64SignedData) {
    let data = '';
    let signature = '';
    const binary = window.atob(base64SignedData);

    if (binary[0] === '\u000A') {
        // Get len of the data part
        let index = 0;
        let len = binary[index + 1];
        let start = index + 2;
        let stop = start + varintDecode(len);
        if (binary[stop] !== '\u0012') {
            // Data length may be 2 bytes long
            len = binary[index + 1] + binary[index + 2];
            start = index + 3;
            stop = start + varintDecode(len);
            if (binary[stop] !== '\u0012') {
                console.log('Decoding error: bad data length');
                return '';
            }
        }

        // Get the data part
        for (let i = start; i < stop; i++) {
            data += binary[i];
        }

        // Get signature length
        index = stop;
        len = binary[index + 1];
        start = index + 2;
        stop = start + varintDecode(len);
        if (stop !== binary.length) {
            // Signature length may be 2 bytes long
            len = binary[index + 1] + binary[index + 2];
            start = index + 3;
            stop = start + varintDecode(len);
            if (stop !== binary.length) {
                console.log('Decoding error: bad signature length');
                return '';
            }
        }

        // Get the signature part
        for (let i = start; i < stop; i++) {
            signature += binary[i];
        }
    } else {
        console.log('Decoding error: first byte is not 0A');
        return '';
    }

    return {
        data: window.btoa(data),
        signature: window.btoa(signature),
    };
}
