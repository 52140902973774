import {html, LitElement} from 'lit-element';

import authIcon from '../../../components/images/auth.svg';
import styles from './app-auth-dialog.scss';
import Cookies from 'js-cookie';
import {decodeSignedData, encodeChallenge} from '../../util/encodeDecode';
import {Backend} from '../../service/backend';

const CHROME_EXTENSION_ID = 'plkmjeckfbeegodpgkmbdfckihpplgdi';

class AppAuthDialog extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            loading: {type: Boolean, reflect: true},
            useSimpleUserAuthenticator: {type: Boolean, reflect: true},
            isLoading: {type: Boolean},
            errorMessage: {type: String},
            deviceSerialNumber: {type: String, state: true},
            isVerifyingDevice: {type: Boolean},
            isVerifiedDevice: {type: Boolean},
        };
    }

    /**
     * @type {Backend}
     */
    backend;

    constructor() {
        super();

        this.challengeResponseCallback = this.challengeResponseCallback.bind(this);

        this.loading = false;
        this.isVerifyingDevice = false;
        this.isVerifiedDevice = !!Cookies.get('auth_bearer');
        this.errorMessage = '';
        this.deviceSerialNumber = null;
        this.backend = new Backend(window.location.origin);
        this.isLoading = false;
        this.useSimpleUserAuthenticator = false;
    }

    firstUpdated() {
        if (!this.useSimpleUserAuthenticator) {
            this.getDeviceSerialNumber();
        } else {
            this.isVerifiedDevice = true;
        }

        setTimeout(() => {
            this.shadowRoot.querySelector('input').focus();
        }, 100);
    }

    updated(changedProperties) {
        if (!this.isVerifiedDevice && changedProperties.has('deviceSerialNumber') && this.deviceSerialNumber) {
            this.verifyDevice(this.deviceSerialNumber);
        }

        this.isLoading = this.loading || this.isVerifyingDevice;
    }

    formSubmit(e) {
        e.preventDefault();
        const code = this.shadowRoot.querySelector('[name="code"]').value;

        this.dispatchEvent(new CustomEvent('loginAttempt', {
            detail: {
                code,
            },
        }));
    }

    render() {
        return html`
            <app-dialog icon="${authIcon}" title="Authenticatie vereist">
                <div class="auth-dialog ${this.isLoading ? '-loading' : ''}">
                    <p>U bent niet geauthenticeerd. Scan je QR-code om verder te gaan.</p>
                    <form name="login" @submit=${this.formSubmit}>
                        <div class="auth-dialog__error" ?hidden=${!this.useSimpleUserAuthenticator}>
                            <p>Verified device auth staat uit!</p>
                        </div>
                        <div class="auth-dialog__error" ?hidden=${this.errorMessage === ''}>
                            <p>${this.errorMessage}</p>
                        </div>
                        <input
                            id="code"
                            name="code"
                            type="text"
                            autocomplete="off"
                            placeholder="Scan je QR-code"
                            ?disabled="${!this.isVerifiedDevice}"
                        />
                    </form>
                </div>
            </app-dialog>
        `;
    }

    async verifyDevice(deviceSerialNumber) {
        try {
            const response = await this.backend.challenge(deviceSerialNumber);
            this.sendChallengeToChromeExtension(encodeChallenge(response.challenge));
        } catch (error) {
            this.errorMessage = 'Er is een fout opgetreden bij het verifieren van het device';
            console.error(error);
            this.isVerifyingDevice = false;
        }
    }

    getDeviceSerialNumber() {
        this.isVerifyingDevice = true;

        try {
            chrome.runtime.sendMessage(
                CHROME_EXTENSION_ID,
                {message: 'getDeviceSerialNumber'},
                (serialNumber) => {
                    this.deviceSerialNumber = serialNumber;

                    if (serialNumber === undefined) {
                        this.errorMessage = 'Er is geen serienummer gevonden voor het apparaat.';
                    }
                },
            );
        } catch (e) {
            console.error(e);
            this.errorMessage = 'Er is een fout opgetreden bij het ophalen van het serienummer van het apparaat.';
            this.isVerifyingDevice = false;
        }
    }

    async challengeResponseCallback(challenge) {
        const signedData = decodeSignedData(challenge);
        if (signedData === undefined) {
            this.errorMessage = 'Er is een fout opgetreden bij het verifieren van het device';
            this.isVerifyingDevice = false;
            return;
        }

        try {
            const response = await this.backend.verify(
                this.deviceSerialNumber,
                signedData,
            );

            if (response.token) {
                const validUntil = new Date(response.validUntil);
                Cookies.set('auth_bearer', response.token, {
                    expires: validUntil,
                });
                this.isVerifiedDevice = true;
            }

            this.isVerifyingDevice = false;
        } catch (e) {
            console.error(e);
            this.errorMessage = 'Er is een fout opgetreden bij het verifieren van het device';
            this.isVerifyingDevice = false;
        }
    }

    sendChallengeToChromeExtension(challenge) {
        chrome.runtime.sendMessage(
            CHROME_EXTENSION_ID,
            {challenge},
            this.challengeResponseCallback,
        );
    }
}

customElements.define('app-auth-dialog', AppAuthDialog);
