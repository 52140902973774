import {html, LitElement} from 'lit-element';
import {repeat} from 'lit-html/directives/repeat.js';
import Cookies from 'js-cookie';

import {ExtensionLoader, extensionLoader} from '../../service/extension-loader';
import {printerManager} from '../../service/printer-manager';
import {PrintJobManager} from '../../service/print-job-manager';
import {HistoryItem} from '../../service/entity/history-item';
import {Debounce} from '../../util/debounce';
import {Backend} from '../../service/backend';
import {isValidEan} from '../../util/ean';
import {historyStore} from '../../service/store/history-store';
import {historyItemFactory} from '../../service/factory/history-item-factory';
import {printJobFactory} from '../../service/factory/print-job-factory';
import {AppMessage} from '../app-message/app-message';
import {Message} from '../../service/entity/message';
import {AutocompleteRequestHandler} from '../../service/autocomplete-request-handler';

import styles from './app-root.scss';
import printerCircleIcon from '../../../components/images/printer-circle.svg';
import extensionIcon from '../../../components/images/plugin.svg';
import {PrintJob} from '../../service/entity/print-job';

const LOCAL_STORAGE_KEY = 'label_app_user';

class AppRoot extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            extensionState: {type: String, state: true},
            selectedPrinter: {type: Object, state: true},
            printers: {type: Array, state: true},
            autocompleteSuggestions: {type: Array, state: true},
            autocompleteIsLoading: {type: Boolean, state: true},
            history: {type: Array, state: true},
            messages: {type: Array, state: true},
            tokenAvailable: {type: Boolean, state: true},
            isAuthenticated: {type: Boolean, state: true},
            isAuthenticating: {type: Boolean, state: true},
            fixedDeviceIdentifier: {type: String, state: true},
        };
    }

    /**
     * @type {Backend}
     */
    backend;

    /**
     * @type {PrintJobManager}
     */
    printJobManager;

    /**
     * @type {Debounce}
     */
    debouncedAutocomplete;

    /**
     * @type {AutocompleteRequestHandler}
     */
    autoCompleteRequestHandler;

    constructor() {
        super();

        this.selectedPrinter = null;
        this.printers = [];
        this.autocompleteIsLoading = false;
        this.autocompleteSuggestions = null;
        this.history = [];
        this.messages = [];
        this.tokenAvailable = false;
        this.isAuthenticating = false;
        this.fixedDeviceIdentifier = null;

        this.setup();
    }

    firstUpdated(props) {
        super.firstUpdated(props);
        setTimeout(() => this.getSearchField().focus(), 100);
    }

    async setup() {
        this.fixedDeviceIdentifier = this.getAttribute('data-use-simple-user-authenticator-identifier') || null;

        this.extensionState = extensionLoader.getState();

        try {
            await extensionLoader.waitForExtension();
        } catch (e) {
            // We can't do anything useful without the extension.
            return;
        } finally {
            this.extensionState = extensionLoader.getState();
        }

        this.backend = new Backend(window.location.origin);
        this.debouncedAutocomplete = new Debounce(() => this.handleAutocomplete(), 300);
        this.autoCompleteRequestHandler = new AutocompleteRequestHandler(this.backend);
        this.restoreHistory();

        this.printJobManager = new PrintJobManager();
        this.printJobManager.addEventListener('statuschange', e => this.onJobStatusChange(e));

        this.selectedPrinter = await printerManager.getSelectedPrinter();
        this.printers = await printerManager.getPrinters();

        this.checkIfTokenAvailable();
        this.checkIfAuthenticated();
    }

    render() {
        return html`
            <div class="app">
                ${this.renderMessages()}
                <div class="app__top">
                    <app-header>
                        ${this.renderPrinterSelect()}
                    </app-header>

                    <app-search-field
                            ?loading=${this.autocompleteIsLoading}
                            @change=${e => this.onSearchFieldChange(e)}
                            @search=${e => this.onSearch(e)}
                    >
                        ${this.renderAutocomplete()}
                    </app-search-field>
                </div>

                <div class="app__bottom">
                    ${this.renderHistory()}
                </div>
            </div>

            ${this.renderAuthDialog()}
            ${this.renderPrinterDialog()}
            ${this.renderExtensionDialog()}
        `;
    }

    renderMessages() {
        if (this.messages.length === 0) {
            return null;
        }

        return html`
            <div class="app__messages">
                ${repeat(this.messages, message => html`
                    <app-message
                            .type=${message.type}
                            @close=${() => this.onMessageClose(message)}
                    >${message.message}
                    </app-message>
                `)}
            </div>
        `;
    }

    renderAutocomplete() {
        if (this.autocompleteSuggestions === null) {
            return null;
        }

        if (this.autocompleteSuggestions.length === 0) {
            return html`
                <app-autocomplete-empty slot="autocomplete"/>
            `;
        }

        return html`
            ${this.autocompleteSuggestions.map(suggestion => html`
                <app-autocomplete-suggestion slot="autocomplete" @click=${() => this.onSuggestionClick(suggestion)}>
                    <app-product-suggestion .product="${suggestion}"/>
                </app-autocomplete-suggestion>
            `)}
        `;
    }

    renderHistory() {
        return html`
            <app-history>
                ${repeat(this.history, item => item.getId(), item => html`
                    <app-history-item
                            .product=${item.getProduct()}
                            status=${item.getStatus()}
                            @reprint=${e => this.onReprint(e)}
                    />
                `)}
            </app-history>
        `;
    }

    renderExtensionDialog() {
        switch (this.extensionState) {
            case ExtensionLoader.STATE_PENDING:
                return html`
                    <app-dialog icon="${extensionIcon}" title="Wachten op extensie">
                        <p>Even geduld, de applicatie is aan het laden.</p>
                    </app-dialog>
                `;
            case ExtensionLoader.STATE_FAILED:
                return html`
                    <app-dialog icon="${extensionIcon}" title="Extensie niet geladen">
                        <p>Het laden van de extensie is mislukt. Controleer of de extensie correct is geïnstalleerd.</p>
                    </app-dialog>
                `;
            default:
                return null;
        }
    }

    renderAuthDialog() {
        if (!this.isAuthenticated) {
            return html`
                <app-auth-dialog
                    @loginAttempt=${this.onLoginAttempt}
                    ?loading=${this.isAuthenticating}
                    ?useSimpleUserAuthenticator=${this.fixedDeviceIdentifier !== null}
                />`;
        }

        return null;
    }

    renderPrinterDialog() {
        if (this.isAuthenticated && this.selectedPrinter === null) {
            return html`
                <app-dialog icon="${printerCircleIcon}" title="Selecteer een printer">
                    ${this.renderPrinterSelect()}
                </app-dialog>
            `;
        }

        return null;
    }

    renderPrinterSelect() {
        return html`
            <app-printer-select @change="${e => this.onPrinterSelectionChange(e)}">
                ${this.printers.map(printer => html`
                    <app-printer-select-option
                            value="${printer.getId()}"
                            ?selected=${this.selectedPrinter === printer}
                    >
                        ${printer.getName()}
                    </app-printer-select-option>
                `)}
            </app-printer-select>
        `;
    }

    onMessageClose(message) {
        const index = this.messages.indexOf(message);
        if (index === -1) {
            throw new Error('Message was not found in state and could not be removed');
        }

        this.messages.splice(index, 1);
        this.messages = [...this.messages];
    }

    async onPrinterSelectionChange(e) {
        const option = e.target.options[e.target.selectedIndex];
        if (option === undefined) {
            printerManager.setSelectedPrinter(null);
            this.selectedPrinter = await printerManager.getSelectedPrinter();
            return;
        }

        this.selectedPrinter = this.printers.find(
            printer => printer.id === e.target.options[e.target.selectedIndex].value
        );
        printerManager.setSelectedPrinter(this.selectedPrinter);
        this.selectedPrinter = await printerManager.getSelectedPrinter();
        this.getSearchField().focus();
    }

    /**
     * @param {Product} suggestion
     */
    onSuggestionClick(suggestion) {
        this.clearSearch();
        this.printProduct(suggestion, 1);
    }

    onReprint(e) {
        this.printProduct(e.detail.product, e.detail.copies);
        this.getSearchField().focus();
    }

    onJobStatusChange(e) {
        /** @type {PrintJob} */
        const updatedJob = e.detail.job;
        const historyItem = this.history.find(item => item.getRelatedPrintJobId() === updatedJob.getId());
        if (historyItem === undefined) {
            return;
        }
        if (updatedJob.getStatus() === PrintJob.STATUS_FAILED) {
            this.addMessage(
                `Het printen van het label voor "${historyItem.getProduct().getName()}" is mislukt.`,
                AppMessage.TYPE_ERROR
            );
        }

        historyItem.setStatusFromJob(updatedJob);
        this.history = [...this.history];
        historyStore.setItems(this.history);
    }

    /**
     * @param {Product} product
     * @param {Number} copies
     */
    printProduct(product, copies) {
        const job = printJobFactory.create(
            this.selectedPrinter.getId(),
            product.getSku(),
            () => this.backend.fetchPdf(product.getSku(), copies)
        );
        this.printJobManager.submitJob(job);
        this.addToHistory(
            historyItemFactory.createForProductAndJobId(product, job.getId(), copies)
        );
    }

    onSearchFieldChange(e) {
        const query = e.target.query;
        if (query.length < 2) {
            this.debouncedAutocomplete.cancel();
            this.autoCompleteRequestHandler.cancel();
            this.autocompleteSuggestions = null;
            return;
        }
        this.debouncedAutocomplete.reset();
    }

    onSearch(e) {
        const query = e.target.query;
        if (isValidEan(query)) {
            // The form was submitted with a valid EAN code, fetch the products. If one result -> print, otherwise show
            // them in the autocomplete.
            this.handleEanSearch(query);
            this.clearSearch();
            this.debouncedAutocomplete.cancel();
            this.autoCompleteRequestHandler.cancel();
            return;
        }

        // If the form was submitted without a valid EAN code, perform a normal autocomplete.
        this.debouncedAutocomplete.resetImmediate();
    }

    async onLoginAttempt(e) {
        this.isAuthenticating = true;
        const {code} = e.detail;
        try {
            if (!this.fixedDeviceIdentifier) {
                const response = await this.backend.login(code);
                if (response.name) {
                    this.isAuthenticated = true;
                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(response));
                }
            } else {
                const response = await this.backend.simpleUserAuthLogin(code, this.fixedDeviceIdentifier);
                if (response.token) {
                    const validUntil = new Date(response.validUntil);

                    Cookies.set('auth_bearer', response.token, {
                        expires: validUntil,
                    });

                    this.isAuthenticated = true;
                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(response));
                }
            }
        } catch (error) {
            console.error(error);
            this.addMessage(
                'Er liep iets mis bij het authenticeren. Probeer het opnieuw.',
                AppMessage.TYPE_ERROR
            );
        }
        this.isAuthenticating = false;
    }

    async handleEanSearch(ean) {
        let products = [];
        try {
            products = await this.backend.searchByEan(ean);
        } catch (e) {
            this.addMessage(
                `Er liep iets mis bij het zoeken naar "ean": ${e.message}`,
                AppMessage.TYPE_ERROR
            );
            return;
        }

        if (products.length === 1) {
            this.printProduct(products[0], 1);
        } else if (this.getSearchField().query === '') {
            /*
             * Multiple or no products with the same EAN code found, handle it like a normal autocomplete as long as no
             * new scan has been performed.
             */
            this.getSearchField().query = ean;
            this.autocompleteSuggestions = products;
        } else if (products.length === 0) {
            this.addMessage(
                `Geen producten met de EAN code "${ean}" gevonden.`,
                AppMessage.TYPE_NOTICE
            );
        } else {
            this.addMessage(
                `Er bestaan meerdere producten met de EAN code "${ean}". Scan het product opnieuw en kies het
                    gewenste product.`,
                AppMessage.TYPE_NOTICE
            );
        }
    }

    async handleAutocomplete() {
        this.autocompleteIsLoading = true;
        try {
            this.autocompleteSuggestions = await this.autoCompleteRequestHandler.fetchSuggestions(
                this.getSearchField().query
            );
        } catch (e) {
            if (e instanceof DOMException && e.name === 'AbortError') {
                this.autocompleteSuggestions = null;
            } else {
                this.addMessage(
                    `Er liep iets mis bij het zoeken naar "${this.getSearchField().query}": ${e.message}`,
                    AppMessage.TYPE_ERROR
                );
            }
        } finally {
            this.autocompleteIsLoading = false;
        }
    }

    clearSearch() {
        this.autocompleteSuggestions = null;
        const field = this.getSearchField();
        field.query = '';
        field.focus();
    }

    getSearchField() {
        return this.shadowRoot.querySelector('app-search-field');
    }

    addToHistory(historyItem) {
        const history = [
            historyItem,
            ...this.history,
        ];

        const itemsToRemove = history.filter((item, index) => {
            // Always keep the 4 most recent items.
            if (index <= 4) {
                return false;
            }

            // Also keep older items until they have been printed.
            return [HistoryItem.STATUS_NEW, HistoryItem.STATUS_PROCESSING].indexOf(item.getStatus()) === -1;
        });

        for (let itemToRemove of itemsToRemove) {
            history.splice(history.indexOf(itemToRemove), 1);
        }

        this.history = [...history];
        historyStore.setItems(this.history);
    }

    addMessage(message, type) {
        this.checkIfTokenAvailable();

        this.messages = [
            new Message(message, type),
            ...this.messages,
        ];
    }

    restoreHistory() {
        this.history = historyStore.getItems().map(item => {
            if (item.getStatus() === HistoryItem.STATUS_PROCESSING) {
                item.setStatus(HistoryItem.STATUS_UNKOWN);
            } else if (item.getStatus() === HistoryItem.STATUS_NEW) {
                item.setStatus(HistoryItem.STATUS_CANCELLED);
            }
            return item;
        });
    }

    checkIfAuthenticated() {
        this.isAuthenticated = !!localStorage.getItem(LOCAL_STORAGE_KEY);
    }

    checkIfTokenAvailable() {
        this.tokenAvailable = !!Cookies.get('auth_bearer');

        if (!this.tokenAvailable) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            this.isAuthenticated = false;
        }
    }
}

customElements.define('app-root', AppRoot);
